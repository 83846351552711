*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Nunito Sans, Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.right-0 {
  right: 0;
}

.right-10 {
  right: 2.5rem;
}

.top-0 {
  top: 0;
}

.top-10 {
  top: 2.5rem;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-6 {
  width: 1.5rem;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-6 {
  gap: 1.5rem;
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(36 36 36 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-36 {
  padding-top: 9rem;
}

.text-right {
  text-align: right;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.05;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media not print {
  .no-js section .container {
    opacity: 1;
    transform: translateX(0);
  }

  header, section {
    position: relative;
  }

  p {
    margin-top: 1rem;
    position: relative;
  }

  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .fade-in .container {
    opacity: 1;
    transform: translateX(0);
  }

  section .container {
    opacity: 0;
    transition: all 1s cubic-bezier(.22, 1, .36, 1);
    transform: translateX(100px);
  }

  h1:before, h2:before, h3:before, h4:before, p:before, nav:before {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity));
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.05;
    position: absolute;
  }

  header:before, section:before {
    content: "";
    clip-path: polygon(0 0, 75% 0, 0 100%);
    z-index: 20;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    width: 100%;
    height: 75px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  section:before {
    top: -1px;
  }

  section:after {
    content: "";
    clip-path: polygon(100% 0, 100% 100%, 25% 100%);
    z-index: 20;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    width: 100%;
    height: 75px;
    display: block;
    position: absolute;
    top: -74px;
  }

  section.bg-black:after {
    background-color: #0000;
  }
}

@media print {
  .print\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .print\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .print\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .print\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .print\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .print\:container {
      max-width: 1536px;
    }
  }
}

@media (width >= 1024px) {
  .lg\:grid {
    display: grid;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:text-7xl {
    font-size: 6rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  .xl\:w-2\/3 {
    width: 66.6667%;
  }
}

@media print {
  .print\:static {
    position: static;
  }

  .print\:relative {
    position: relative;
  }

  .print\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:mb-0 {
    margin-bottom: 0;
  }

  .print\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .print\:mb-3 {
    margin-bottom: .75rem;
  }

  .print\:ml-4 {
    margin-left: 1rem;
  }

  .print\:mt-0 {
    margin-top: 0;
  }

  .print\:block {
    display: block;
  }

  .print\:inline {
    display: inline;
  }

  .print\:hidden {
    display: none;
  }

  .print\:min-h-0 {
    min-height: 0;
  }

  .print\:list-disc {
    list-style-type: disc;
  }

  .print\:break-inside-avoid {
    break-inside: avoid;
  }

  .print\:gap-3 {
    gap: .75rem;
  }

  .print\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .print\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .print\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .print\:pb-3 {
    padding-bottom: .75rem;
  }

  .print\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .print\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .print\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .print\:font-bold {
    font-weight: 700;
  }

  .print\:font-medium {
    font-weight: 500;
  }

  .print\:text-black {
    --tw-text-opacity: 1;
    color: rgb(36 36 36 / var(--tw-text-opacity));
  }

  .print\:underline {
    text-decoration-line: underline;
  }

  @media (width >= 1024px) {
    .print\:lg\:flex {
      display: flex;
    }

    .print\:lg\:w-auto {
      width: auto;
    }

    .print\:lg\:text-2xl {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .print\:lg\:text-base {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .print\:lg\:text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }

  @media (width >= 1280px) {
    .print\:xl\:w-auto {
      width: auto;
    }
  }
}
/*# sourceMappingURL=index.480c32d1.css.map */
